import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { TemplateOffer } from '../templates';
import { Heading, Element } from '@arturpol/style-guide/src/components';
export const meta = {
  title: 'Offer',
  type: 'page',
  hint: 'Our offer with packages',
  order: 48,
  seoTitle: 'Offer - App Design and Graphic Design Packages',
  seoDescription: 'Human Design packages for your application and for your brand. Starting from $1,299.'
};
export const banner = {
  title: <>We design for free; <Heading as="div" size={6} className="title-break" mdxType="Heading">expertise is what you pay for</Heading></>,
  services: {
    title: 'What’s on the menu?',
    items: [{
      title: 'Application — user interface design',
      tags: ['UI', 'web', 'mobile', 'app', 'SaaS', 'mockups']
    }, {
      title: 'Product — user experience design',
      tags: ['UX', 'research', 'prototype', 'user', 'onboarding']
    }, {
      title: 'Digital brand — visual identity',
      tags: ['design', 'system', 'logo', 'typography']
    }]
  },
  image: {
    alt: 'Our offer'
  }
};
export const packages = {
  text: {
    featuresTitle: 'What’s included?',
    descriptionTitle: 'What’s the value for me?',
    fixedPriceTitle: 'How much?',
    fixedDurationTitle: 'How long?',
    hourlyPriceTitle: 'Price from',
    hourlyDurationTitle: 'Duration from',
    cta: {
      label: 'Contact us',
      hint: 'Contact us',
      url: '#/contact/?subject={package}&version={version}'
    },
    versionLabel: 'Select version'
  },
  sections: [{
    id: 'starter',
    title: <>Our <Element as="em" mdxType="Element">starter</Element> packages</>,
    packages: [{
      id: 'blueprint',
      title: <>Human <Heading as="span" size={4} mdxType="Heading">Application Blueprint</Heading></>,
      link: {
        label: 'Learn more about our workflow',
        url: '/about/',
        hint: 'Learn more about our workflow'
      },
      versions: [{
        id: 'basic',
        animation: true,
        animationId: 'wireframe',
        features: ['Clickable online prototype', 'Desktop and/or mobile', '20 wireframes', 'Black & white', 'Core views and functions'],
        description: 'Validate your idea early by testing with actual users. Engage investors by walking them through your prototype. Use wireframes in a brief to get more accurate pricing from your development team.',
        price: '$2,499',
        duration: '3 weeks',
        isFixedPrice: true
      }]
    }, {
      id: 'branding',
      title: <>Human <Heading as="span" size={4} mdxType="Heading">Brand Identity</Heading></>,
      versions: [{
        id: 'basic',
        animation: true,
        animationId: 'brand-basic',
        name: 'Essential',
        features: ['Basic style guide', 'Logo and emblem', 'Moodboard, patterns & photos', 'Typography', 'Color palette'],
        description: 'Give your brand a unique look and identity to attract your target audience. Use a style guide to keep your marketing visually coherent across all channels. Get tangible design assets and logos.',
        price: '$1,299',
        duration: '2-3 weeks',
        isFixedPrice: true
      }, {
        id: 'pro',
        animation: true,
        animationId: 'brand-pro',
        name: 'Business',
        features: ['All the Essentials list plus:', 'Full visual identity style guide', 'Investor deck (template)', 'Business card design', 'Letterhead and envelope'],
        description: 'Establish visual language coherent with your brand. Get a full style guide with grid templates, print guidelines, iconography and stationery design. Use slide templates to wow potential investors with a sleek presentation.',
        price: '$2,599',
        duration: '5 weeks',
        isFixedPrice: true
      }]
    }]
  }, {
    id: 'general',
    title: <>Need a <Element as="em" mdxType="Element">comprehensive</Element> solution?</>,
    packages: [{
      id: 'design',
      title: <>Human <Heading as="span" size={4} mdxType="Heading">Application Design</Heading></>,
      link: {
        label: 'Learn more about our workflow',
        url: '/about/',
        hint: 'Learn more about our workflow'
      },
      versions: [{
        id: 'basic',
        animation: true,
        animationId: 'design',
        features: ['High fidelity clickable prototype', 'Development-ready mockups', 'Component style guide with assets', 'All necessary mockups', 'Custom scope, design & workflow'],
        description: 'Translate your idea into a prototype based on hi-fi mockups. Focus on the business side and high-level decision-making while we handle mundane product design tasks from competition analysis through user flow design. Development team support is included.',
        price: '$4,999*',
        duration: '1.5 month*',
        isFixedPrice: false,
        footer: '*Time & material billing'
      }]
    }]
  }]
};
export const _frontmatter = {};
const layoutProps = {
  meta,
  banner,
  packages,
  _frontmatter
};
const MDXLayout = TemplateOffer;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      